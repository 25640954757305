import ReactMarkdown from "react-markdown";
import React, { FunctionComponent } from "react";
import "./markdown.css";
import rehypeRaw from "rehype-raw";

interface IProps {
  content: string;
}

const Markdown: FunctionComponent<IProps> = ({ content }) => {
  return (
    <div className="markdown-body">
      <ReactMarkdown children={content} rehypePlugins={[rehypeRaw]} />
    </div>
  );
};

export default Markdown;
