import { Box, Center } from "@chakra-ui/layout";
import React, { useMemo } from "react";
// import Particles from "react-particles-js";
import { useEffect } from "react";
import TextLoop from "react-text-loop";
import "../styles.css";
import particles from "./particles.json";
export default function Welcome() {
  useEffect(() => {
    particlesJS("particles-js", particles);
  }, []);
  const skills = useMemo(
    () => [
      "Games",
      "Social networks",
      "Many",
      "Chatbots",
      "ML platforms",
      "APIs and SDKs",
      "Crypto games",
      "NFT art",
    ],
    []
  );

  return (
    <Box backgroundColor="black" className="home-page-container">
      <Box
        id="particles-js"
        position="absolute"
        top="0px"
        w="100%"
        height="500px"
      ></Box>
      <Box maxWidth="1440px" margin="0 auto" px={4}>
        <Box className="welcome-to-my-world">
          <Box w={{ base: "100%", lg: "600px" }}>
            <Center className="container" height="500px">
              <Box ml={{ base: "25px !important", sm: "0px" }}>
                <Box as="span" color="rgba(198,201,216,0.75)">
                  Welcome to my world
                </Box>
                <Box
                  className="title"
                  fontSize={{ base: "30px", lg: "50px" }}
                  fontWeight={800}
                >
                  <Box color="white">Hi, I’m David 'DC' Collier</Box>
                  <Box color="#f9004d">
                    <TextLoop>
                      {skills.map((item, index) => (
                        <Box
                          as="span"
                          key={index}
                          fontSize={{ base: "20px", lg: "40px" }}
                        >
                          {item}
                        </Box>
                      ))}
                    </TextLoop>
                  </Box>
                </Box>
                <Box color="white" fontSize="20px" fontWeight={610}>
                  based in America.
                </Box>
              </Box>
            </Center>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
