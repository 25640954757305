export const listTags = [
    {
        "tag": "AB-testing",
        "totalCount": 2
    },
    {
        "tag": "Authoring",
        "totalCount": 4
    },
    {
        "tag": "BigQuery",
        "totalCount": 1
    },
    {
        "tag": "BizDev",
        "totalCount": 2
    },
    {
        "tag": "Chatbots",
        "totalCount": 3
    },
    {
        "tag": "ConvoAI",
        "totalCount": 1
    },
    {
        "tag": "Demos",
        "totalCount": 1
    },
    {
        "tag": "Design",
        "totalCount": 1
    },
    {
        "tag": "DialogFlow",
        "totalCount": 1
    },
    {
        "tag": "ERC721",
        "totalCount": 2
    },
    {
        "tag": "EdTech",
        "totalCount": 1
    },
    {
        "tag": "Element-ui",
        "totalCount": 1
    },
    {
        "tag": "FastText",
        "totalCount": 1
    },
    {
        "tag": "Flash",
        "totalCount": 2
    },
    {
        "tag": "Flask",
        "totalCount": 1
    },
    {
        "tag": "Game Design",
        "totalCount": 1
    },
    {
        "tag": "Game-Design",
        "totalCount": 4
    },
    {
        "tag": "Gensim",
        "totalCount": 1
    },
    {
        "tag": "Google Cloud",
        "totalCount": 1
    },
    {
        "tag": "Growth",
        "totalCount": 3
    },
    {
        "tag": "JavaScript",
        "totalCount": 10
    },
    {
        "tag": "ML",
        "totalCount": 1
    },
    {
        "tag": "MachineLearning",
        "totalCount": 2
    },
    {
        "tag": "Meteor",
        "totalCount": 1
    },
    {
        "tag": "MeteorJS",
        "totalCount": 1
    },
    {
        "tag": "Metrics",
        "totalCount": 6
    },
    {
        "tag": "MiniBot",
        "totalCount": 3
    },
    {
        "tag": "MongoDB",
        "totalCount": 8
    },
    {
        "tag": "MySQL",
        "totalCount": 1
    },
    {
        "tag": "NFT",
        "totalCount": 4
    },
    {
        "tag": "NLG",
        "totalCount": 1
    },
    {
        "tag": "NLP",
        "totalCount": 6
    },
    {
        "tag": "NodeJS",
        "totalCount": 9
    },
    {
        "tag": "PostgreSQL",
        "totalCount": 1
    },
    {
        "tag": "Presentations",
        "totalCount": 1
    },
    {
        "tag": "Python",
        "totalCount": 3
    },
    {
        "tag": "ReactJS",
        "totalCount": 3
    },
    {
        "tag": "Redux",
        "totalCount": 1
    },
    {
        "tag": "RiBot",
        "totalCount": 7
    },
    {
        "tag": "Ruby",
        "totalCount": 1
    },
    {
        "tag": "Ruby on Rails",
        "totalCount": 1
    },
    {
        "tag": "SNS",
        "totalCount": 2
    },
    {
        "tag": "Sales",
        "totalCount": 1
    },
    {
        "tag": "Socket.io",
        "totalCount": 3
    },
    {
        "tag": "Solidity",
        "totalCount": 2
    },
    {
        "tag": "TensorFlow",
        "totalCount": 1
    },
    {
        "tag": "TypeScript",
        "totalCount": 8
    },
    {
        "tag": "UX-Design",
        "totalCount": 1
    },
    {
        "tag": "Unity3D",
        "totalCount": 1
    },
    {
        "tag": "VueJS",
        "totalCount": 1
    },
    {
        "tag": "WeChat",
        "totalCount": 3
    },
    {
        "tag": "WordVectors",
        "totalCount": 3
    },
    {
        "tag": "ZAT",
        "totalCount": 4
    },
    {
        "tag": "p5.js",
        "totalCount": 2
    },
    {
        "tag": "小程序",
        "totalCount": 3
    },
    {
        "tag": "微信",
        "totalCount": 3
    }
]