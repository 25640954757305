import { Box } from "@chakra-ui/layout";
import React from "react";
import { FiChevronUp } from "react-icons/fi";
import ScrollToTop from "react-scroll-up";
import Contact from "./Contact";
import Introduction from "./Introduction";
import Skills from "./Skills";
import "./styles.css";
import Welcome from "./Welcome";

export default function HomePage() {
  return (
    <Box className="home-page-container">
      <Welcome />
      <Introduction />
      <Skills />
      <Contact />
      <Box className="backto-top" color="white">
        <ScrollToTop showUnder={160}>
          <FiChevronUp />
        </ScrollToTop>
      </Box>
    </Box>
  );
}
