import {
  Box,
  Container,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import React from "react";
import Socials from "./common/Socials";

export default function Footer() {
  return (
    <Box
      bg={useColorModeValue("gray.50", "gray.900")}
      color="white"
      background="#191919"
    >
      <Box maxWidth="1440px" margin="0 auto" px={4}>
        <Container
          as={Stack}
          maxW="100%"
          py={4}
          direction={{ base: "column", md: "row" }}
          spacing={4}
          justify={{ base: "center", md: "space-between" }}
          align={{ base: "center", md: "center" }}
        >
          <Stack
            direction={"row"}
            as={"nav"}
            spacing={6}
            ml={{ base: "0px", md: "20px" }}
          >
            <Socials />
          </Stack>
          <Stack
            mr={{ base: "0px", md: "20px !important" }}
            fontSize={{ base: "12px", lg: "20px" }}
          >
            <Text>Copyright © 2021 Dcsan. All Rights Reserved.</Text>
          </Stack>
        </Container>
      </Box>
    </Box>
  );
}
