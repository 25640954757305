import { Box, Tag, TagLabel } from "@chakra-ui/react";
import React from "react";
import Masonry from "react-masonry-css";
import ProjectCardItem from "./ProjectCardItem";
import { listProjects } from "../../content/projects/listProjects";
import { listTags } from "../../content/tags/listTags";
import "./styles.css";
import { useCallback, useState, useMemo } from "react";
import { useLocation, useHistory } from "react-router-dom";

export default function ProjectPage() {
  const history = useHistory();
  const search = useLocation().search;
  const tag = new URLSearchParams(search).get("tag");
  const [selectedTag, setSelectedTag] = useState<string>(tag || "All");

  const breakpointColumnsObj = useMemo(() => {
    return {
      default: 4,
      1100: 3,
      800: 2,
      500: 1,
    };
  }, []);

  const selectTag = useCallback(
    (tag) => {
      setSelectedTag(tag);
      history.push(`/projects?tag=${tag}`);
    },
    [history]
  );

  const projects = useMemo(() => {
    let results = listProjects;
    if (selectedTag !== "All") {
      results = listProjects.filter((item) => {
        return item.node.frontmatter.tags.includes(selectedTag);
      });
    }
    return results;
  }, [selectedTag]);

  return (
    <Box
      padding="30px 5%"
      display={{ base: "unset", md: "flex" }}
      backgroundColor="#fff"
    >
      <Box w={{ base: "100%", md: "33%", lg: "25%", xl: "20%" }} p="10px">
        {[
          {
            tag: "All",
            totalCount: listProjects.length,
          },
          ...listTags,
        ].map(({ tag, totalCount }, index) => (
          <Tag
            key={index}
            size="sm"
            margin="5px"
            variant="outline"
            color={selectedTag === tag ? "#fff" : "#070"}
            backgroundColor={selectedTag === tag ? "#444" : "#fff"}
            cursor="pointer"
            onClick={() => selectTag(tag)}
          >
            <TagLabel>
              {tag} ({totalCount})
            </TagLabel>
          </Tag>
        ))}
      </Box>
      <Box w={{ base: "100%", md: "67%", lg: "75%", xl: "80%" }} p="10px">
        <Masonry
          breakpointCols={breakpointColumnsObj}
          className="my-masonry-grid"
          columnClassName="my-masonry-grid_column"
        >
          {projects.map((pro, index) => (
            <ProjectCardItem key={index} project={pro.node.frontmatter} />
          ))}
        </Masonry>
      </Box>
    </Box>
  );
}
