import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Box, Tag, TagLabel, Button, Flex } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import matter from "gray-matter";
import Markdown from "../Markdown";
import { listProjects } from "../../content/projects/listProjects";
import { ArrowForwardIcon, ArrowBackIcon } from "@chakra-ui/icons";

function ProjectDetail() {
  const { projectName } = useParams<{ projectName: string }>();
  const [contentProject, setContentProject] = useState<any>(null);
  const [currentPosition, setCurrentPosition] = useState<number>(-1);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    import(`../../content/projects/${projectName}/index.md`)
      .then((res) => {
        fetch(res.default)
          .then((res) => res.text())
          .then((res) => {
            const info = matter(res);
            const project = {
              meta: {
                ...info.data,
              },
              content: info.content,
            };
            setContentProject(project);
            const currentPosition = listProjects
              .map((item) => item.node.frontmatter.path)
              .lastIndexOf(project?.meta.path);
            setCurrentPosition(currentPosition);
          });
      })
      .catch((err) => console.log(err));
  }, [projectName]);

  const renderNextPrevious = () =>
    currentPosition !== -1 && (
      <Flex justifyContent="space-between">
        <Box width="50%">
          {currentPosition !== 0 && (
            <Link to={listProjects[currentPosition - 1].node.frontmatter.path}>
              <Button
                overflow="hidden"
                maxWidth="100%"
                whiteSpace="break-spaces"
                leftIcon={<ArrowBackIcon />}
                colorScheme="teal"
                variant="outline"
              >
                {listProjects[currentPosition - 1].node.frontmatter.title}
              </Button>
            </Link>
          )}
        </Box>
        <Box width="50%" textAlign="right">
          {currentPosition !== listProjects.length - 1 && (
            <Link to={listProjects[currentPosition + 1].node.frontmatter.path}>
              <Button
                overflow="hidden"
                maxWidth="100%"
                whiteSpace="break-spaces"
                rightIcon={<ArrowForwardIcon />}
                colorScheme="teal"
                variant="outline"
              >
                {listProjects[currentPosition + 1].node.frontmatter.title}
              </Button>
            </Link>
          )}
        </Box>
      </Flex>
    );

  return (
    <Box
      padding={{ lg: "50px 20%", md: "50px 10%", base: "20px 5%" }}
      backgroundColor="#fff"
    >
      <Box marginBottom="30px">{renderNextPrevious()}</Box>
      {contentProject?.meta?.tags.map((tag: string, index: number) => (
        <Link key={index} to={`/projects?tag=${tag}`}>
          <Tag
            size="sm"
            margin="5px"
            variant="outline"
            color="#070"
            backgroundColor="#fff"
          >
            <TagLabel>{tag}</TagLabel>
          </Tag>
        </Link>
      ))}
      <Markdown content={contentProject?.content} />
      <Box marginTop="30px" marginBottom="30px">
        {renderNextPrevious()}
      </Box>
    </Box>
  );
}

export default ProjectDetail;
