export const listProjects = [
    {
        "node": {
            "id": "ac7fe86d-ea63-5922-b150-ba2f03dc0285",
            "frontmatter": {
                "title": "ZeroContact",
                "client": "NFT Puzzle",
                "company": null,
                "order": 1200,
                "path": "/projects/zerocontact",
                "sm": 3,
                "xs": 3,
                "where": null,
                "from": null,
                "until": null,
                "tags": [
                    "NFT",
                    "ERC721",
                    "Solidity"
                ],
                "cover_image": "assets/projects/zcon-map.jpg"
            },
            "fields": {
                "slug": "/projects/zerocontact/"
            }
        }
    },
    {
        "node": {
            "id": "75afe77a-45a5-5bb5-87a3-c470b0dc3b62",
            "frontmatter": {
                "title": "Grapheme",
                "client": "NFT hackathon",
                "company": null,
                "order": 1100,
                "path": "/projects/grapheme",
                "sm": 3,
                "xs": 3,
                "where": null,
                "from": null,
                "until": null,
                "tags": [
                    "NFT",
                    "ERC721",
                    "Solidity"
                ],
                "cover_image": "assets/projects/words.png"
            },
            "fields": {
                "slug": "/projects/grapheme/"
            }
        }
    },
    {
        "node": {
            "id": "a93c396b-3f7b-57d2-b73b-d71f3ba9f138",
            "frontmatter": {
                "title": "Conversational AI Architect",
                "client": "Google / TEKSystems",
                "company": "Google/TEK",
                "order": 1000,
                "path": "/projects/ccai",
                "sm": 6,
                "xs": 12,
                "where": null,
                "from": null,
                "until": null,
                "tags": [
                    "Python",
                    "TypeScript",
                    "NLP",
                    "DialogFlow",
                    "BigQuery",
                    "Google Cloud",
                    "TensorFlow",
                    "MachineLearning",
                    "Chatbots"
                ],
                "cover_image": "assets/projects/ccai-flow.png"
            },
            "fields": {
                "slug": "/projects/ccai/"
            }
        }
    },
    {
        "node": {
            "id": "b08c76cf-f21d-5321-9a38-9b4d4195d5d5",
            "frontmatter": {
                "title": "TalkingBlocks NFT Art",
                "client": "personal",
                "company": "ArtBlocks",
                "order": 900,
                "path": "/projects/blocks",
                "sm": 3,
                "xs": 12,
                "where": null,
                "from": null,
                "until": null,
                "tags": [
                    "NodeJS",
                    "TypeScript",
                    "p5.js",
                    "NFT"
                ],
                "cover_image": "assets/projects/blocks.png"
            },
            "fields": {
                "slug": "/projects/blocks/"
            }
        }
    },
    {
        "node": {
            "id": "a5e553d5-75bc-594c-ae1d-4c57481432c1",
            "frontmatter": {
                "title": "True Riddles",
                "client": "TEN",
                "company": "TEN",
                "order": 750,
                "path": "/projects/riddles",
                "sm": 3,
                "xs": 12,
                "where": null,
                "from": null,
                "until": null,
                "tags": [
                    "NodeJS",
                    "TypeScript",
                    "p5.js",
                    "NFT"
                ],
                "cover_image": "assets/projects/riddle-card.png"
            },
            "fields": {
                "slug": "/projects/riddles/"
            }
        }
    },
    {
        "node": {
            "id": "bd0ff9a1-c3be-51a8-b37b-53a502ac9743",
            "frontmatter": {
                "title": "TEN",
                "client": "coop chat games for remote friends",
                "company": "me",
                "order": 700,
                "path": "/projects/ten",
                "sm": 3,
                "xs": 12,
                "where": null,
                "from": null,
                "until": null,
                "tags": [
                    "Chatbots",
                    "Game Design",
                    "TypeScript",
                    "JavaScript"
                ],
                "cover_image": "assets/projects/duo-square.jpg"
            },
            "fields": {
                "slug": "/projects/ten/"
            }
        }
    },
    {
        "node": {
            "id": "0928c540-b6aa-5b00-80dc-1e8402c2b34b",
            "frontmatter": {
                "title": "BAP Bot Application Platform",
                "client": "RIKAI Labs",
                "company": "RIKAI Labs",
                "order": 500,
                "path": "/projects/bap",
                "sm": 6,
                "xs": 12,
                "where": null,
                "from": null,
                "until": null,
                "tags": [
                    "NodeJS",
                    "Socket.io",
                    "RiBot",
                    "Redux",
                    "ReactJS",
                    "TypeScript",
                    "MongoDB",
                    "NLP"
                ],
                "cover_image": "assets/projects/bap-cover.png"
            },
            "fields": {
                "slug": "/projects/bap/"
            }
        }
    },
    {
        "node": {
            "id": "83fdc23e-eb44-5898-a890-5df31195221c",
            "frontmatter": {
                "title": "Hybrid Pipeline",
                "client": "RIKAI Labs",
                "company": "RIKAI Labs",
                "order": 400,
                "path": "/projects/hybrid",
                "sm": 3,
                "xs": 12,
                "where": null,
                "from": null,
                "until": null,
                "tags": [
                    "NodeJS",
                    "ReactJS",
                    "WeChat",
                    "ZAT",
                    "Authoring",
                    "Socket.io",
                    "TypeScript",
                    "JavaScript",
                    "NLP"
                ],
                "cover_image": "assets/projects/hybrid-cover.jpg"
            },
            "fields": {
                "slug": "/projects/hybrid/"
            }
        }
    },
    {
        "node": {
            "id": "18cdbf40-5f2f-5eb4-b299-d28ea8973fcd",
            "frontmatter": {
                "title": "BizDev",
                "client": "RIKAI Labs",
                "company": "RIKAI Labs",
                "order": 300,
                "path": "/projects/bizdev",
                "sm": 3,
                "xs": 12,
                "where": null,
                "from": null,
                "until": null,
                "tags": [
                    "BizDev",
                    "Sales",
                    "Presentations"
                ],
                "cover_image": "assets/projects/bizdev-cover.jpg"
            },
            "fields": {
                "slug": "/projects/bizdev/"
            }
        }
    },
    {
        "node": {
            "id": "e6828357-b880-5bc7-b1f9-d8d2a8c16256",
            "frontmatter": {
                "title": "ConvoAI",
                "client": "RIKAI Labs",
                "company": "RIKAI Labs",
                "order": 300,
                "path": "/projects/convoai",
                "sm": 3,
                "xs": 12,
                "where": null,
                "from": null,
                "until": null,
                "tags": [
                    "ConvoAI",
                    "ML",
                    "Demos"
                ],
                "cover_image": "assets/projects/grafr-3.png"
            },
            "fields": {
                "slug": "/projects/convoai/"
            }
        }
    },
    {
        "node": {
            "id": "0103f531-bbad-57b7-8ff6-56b02a691787",
            "frontmatter": {
                "title": "MiniBot Apps",
                "client": "RIKAI Labs",
                "company": "RIKAI Labs",
                "order": 300,
                "path": "/projects/minibot-demos",
                "sm": 6,
                "xs": 12,
                "where": null,
                "from": null,
                "until": null,
                "tags": [
                    "Authoring",
                    "MiniBot",
                    "JavaScript",
                    "ZAT",
                    "小程序",
                    "微信",
                    "MongoDB"
                ],
                "cover_image": "assets/projects/mb-demos.png"
            },
            "fields": {
                "slug": "/projects/minibot-demos/"
            }
        }
    },
    {
        "node": {
            "id": "43109256-4bde-5839-ac1d-b8201f705ec1",
            "frontmatter": {
                "title": "MiniBot Framework",
                "client": "RIKAI Labs",
                "company": "RIKAI Labs",
                "order": 300,
                "path": "/projects/minibot",
                "sm": 3,
                "xs": 6,
                "where": null,
                "from": null,
                "until": null,
                "tags": [
                    "Authoring",
                    "MiniBot",
                    "JavaScript",
                    "ZAT",
                    "小程序",
                    "微信",
                    "MongoDB"
                ],
                "cover_image": "assets/projects/mb-choices.jpg"
            },
            "fields": {
                "slug": "/projects/minibot/"
            }
        }
    },
    {
        "node": {
            "id": "44d33f2b-c237-5019-b774-7f73d6b648ca",
            "frontmatter": {
                "title": "NAP",
                "client": "RIKAI Labs",
                "company": "RIKAI Labs",
                "order": 250,
                "path": "/projects/nap",
                "sm": 6,
                "xs": 12,
                "where": null,
                "from": null,
                "until": null,
                "tags": [
                    "TypeScript",
                    "VueJS",
                    "FastText",
                    "Element-ui",
                    "JavaScript",
                    "MongoDB",
                    "NLP",
                    "WordVectors"
                ],
                "cover_image": "assets/projects/nap-map.png"
            },
            "fields": {
                "slug": "/projects/nap/"
            }
        }
    },
    {
        "node": {
            "id": "87687717-86d7-55fe-9ddb-f47261fe8206",
            "frontmatter": {
                "title": "BabyBot",
                "client": "MeadJohnson",
                "company": "RIKAI Labs",
                "order": 200,
                "path": "/projects/babybot",
                "sm": 3,
                "xs": 6,
                "where": null,
                "from": null,
                "until": null,
                "tags": [
                    "NodeJS",
                    "Metrics",
                    "RiBot",
                    "Python",
                    "Gensim",
                    "JavaScript",
                    "NLP",
                    "WordVectors"
                ],
                "cover_image": "assets/projects/bbot-cover.jpg"
            },
            "fields": {
                "slug": "/projects/babybot/"
            }
        }
    },
    {
        "node": {
            "id": "3ab3e95e-faaf-595e-9c71-35d6422e916f",
            "frontmatter": {
                "title": "Beer Bot",
                "client": "Budweiser",
                "company": "RIKAI Labs",
                "order": 200,
                "path": "/projects/beerbot",
                "sm": 3,
                "xs": 6,
                "where": null,
                "from": null,
                "until": null,
                "tags": [
                    "NodeJS",
                    "RiBot",
                    "AB-testing",
                    "Metrics",
                    "WeChat"
                ],
                "cover_image": "assets/projects/bud-cover.png"
            },
            "fields": {
                "slug": "/projects/beerbot/"
            }
        }
    },
    {
        "node": {
            "id": "c328555c-bd76-5dcf-9708-239aa56e376c",
            "frontmatter": {
                "title": "Cocktail Bot",
                "client": "Pernod Ricard",
                "company": "RIKAI Labs",
                "order": 200,
                "path": "/projects/askj",
                "sm": 3,
                "xs": 6,
                "where": null,
                "from": null,
                "until": null,
                "tags": [
                    "NodeJS",
                    "RiBot",
                    "AB-testing",
                    "Metrics",
                    "JavaScript"
                ],
                "cover_image": "assets/projects/aj-cover.png"
            },
            "fields": {
                "slug": "/projects/askj/"
            }
        }
    },
    {
        "node": {
            "id": "1f87cc3d-c8ef-55fd-b93f-7fb258a332b4",
            "frontmatter": {
                "title": "Pharma Bot",
                "client": "Sanofi",
                "company": "RIKAI Labs",
                "order": 200,
                "path": "/projects/pharmabot",
                "sm": 3,
                "xs": 6,
                "where": "shanghai and san francisco",
                "from": "17/03/2019",
                "until": "17/03/2019",
                "tags": [
                    "NodeJS",
                    "RiBot",
                    "NLG",
                    "JavaScript",
                    "WordVectors",
                    "MongoDB"
                ],
                "cover_image": "assets/projects/sanofi-award.jpg"
            },
            "fields": {
                "slug": "/projects/pharmabot/"
            }
        }
    },
    {
        "node": {
            "id": "f9523081-0d49-5f5a-8695-64f02b3bd323",
            "frontmatter": {
                "title": "Speaking",
                "client": "RIKAI",
                "company": "RIKAI Labs",
                "order": 200,
                "path": "/projects/speaking",
                "sm": 3,
                "xs": 6,
                "where": null,
                "from": null,
                "until": null,
                "tags": [
                    "RiBot",
                    "Metrics",
                    "Growth",
                    "Meteor",
                    "BizDev"
                ],
                "cover_image": "assets/projects/speaking.jpg"
            },
            "fields": {
                "slug": "/projects/speaking/"
            }
        }
    },
    {
        "node": {
            "id": "4a8d4509-96cb-5778-aa4f-9118782107c4",
            "frontmatter": {
                "title": "TravelBot",
                "client": "VISA",
                "company": "RIKAI Labs",
                "order": 200,
                "path": "/projects/travelbot",
                "sm": 3,
                "xs": 6,
                "where": null,
                "from": null,
                "until": null,
                "tags": [
                    "RiBot",
                    "Metrics",
                    "Growth",
                    "MongoDB"
                ],
                "cover_image": "assets/projects/mtl-cover.jpg"
            },
            "fields": {
                "slug": "/projects/travelbot/"
            }
        }
    },
    {
        "node": {
            "id": "158bb1e9-0658-540e-9df6-2a6cd08e2dc1",
            "frontmatter": {
                "title": "Comic English",
                "client": "RIKAI",
                "company": "RIKAI Labs",
                "order": 150,
                "path": "/projects/comic-english",
                "sm": 3,
                "xs": 6,
                "where": null,
                "from": null,
                "until": null,
                "tags": [
                    "MeteorJS",
                    "Design",
                    "UX-Design",
                    "EdTech",
                    "MongoDB",
                    "Game-Design"
                ],
                "cover_image": "assets/projects/comic-english-cover-2.jpg"
            },
            "fields": {
                "slug": "/projects/comic-english/"
            }
        }
    },
    {
        "node": {
            "id": "31d25649-dde3-5d5c-8c71-4686f8ddf2a5",
            "frontmatter": {
                "title": "CamperBot",
                "client": "FreeCodeCamp",
                "company": "RIKAI Labs",
                "order": 120,
                "path": "/projects/gitterbot",
                "sm": 6,
                "xs": 12,
                "where": null,
                "from": null,
                "until": null,
                "tags": [
                    "Authoring",
                    "MiniBot",
                    "JavaScript",
                    "ZAT",
                    "小程序",
                    "微信",
                    "MongoDB"
                ],
                "cover_image": "assets/projects/gitterbot.png"
            },
            "fields": {
                "slug": "/projects/gitterbot/"
            }
        }
    },
    {
        "node": {
            "id": "267aedda-2bf9-5c45-98ee-30851f5f04c0",
            "frontmatter": {
                "title": "Hackathons",
                "client": "just for fun",
                "company": "me",
                "order": 120,
                "path": "/projects/hackathons",
                "sm": 3,
                "xs": 12,
                "where": null,
                "from": null,
                "until": null,
                "tags": [
                    "NodeJS",
                    "ReactJS",
                    "WeChat",
                    "MachineLearning",
                    "Chatbots",
                    "Socket.io",
                    "TypeScript",
                    "JavaScript",
                    "NLP"
                ],
                "cover_image": "assets/projects/launch-check.jpg"
            },
            "fields": {
                "slug": "/projects/hackathons/"
            }
        }
    },
    {
        "node": {
            "id": "d0c81f28-3149-5d0f-802d-debbd59159ec",
            "frontmatter": {
                "title": "Crystal Casters",
                "client": "KLab",
                "company": "KLab",
                "order": 100,
                "path": "/projects/casters",
                "sm": 3,
                "xs": 6,
                "where": "tokyo and san francisco",
                "from": "17/03/2019",
                "until": "17/03/2019",
                "tags": [
                    "Unity3D",
                    "Python",
                    "Flask",
                    "Metrics",
                    "Growth",
                    "PostgreSQL",
                    "Game-Design"
                ],
                "cover_image": "assets/projects/casters-cover.png"
            },
            "fields": {
                "slug": "/projects/casters/"
            }
        }
    },
    {
        "node": {
            "id": "d3ae91bf-d517-5aec-939f-9b2483ca7cdf",
            "frontmatter": {
                "title": "JooJoo Japanese SNS",
                "client": "Pikkle/AdWays",
                "company": "Pikkle KK",
                "order": 90,
                "path": "/projects/joojoo",
                "sm": 3,
                "xs": 12,
                "where": null,
                "from": null,
                "until": null,
                "tags": [
                    "Ruby on Rails",
                    "Flash",
                    "Game-Design",
                    "MySQL",
                    "SNS"
                ],
                "cover_image": "assets/projects/joojoo-cover.jpg"
            },
            "fields": {
                "slug": "/projects/joojoo/"
            }
        }
    },
    {
        "node": {
            "id": "1e38fdd8-b3e7-5f8d-811d-8c3cc841854f",
            "frontmatter": {
                "title": "WonderDays",
                "client": "Disney Japan",
                "company": "Pikkle KK",
                "order": 70,
                "path": "/projects/wonder-days",
                "sm": 3,
                "xs": 12,
                "where": null,
                "from": null,
                "until": null,
                "tags": [
                    "Flash",
                    "SNS",
                    "Game-Design",
                    "Ruby"
                ],
                "cover_image": "assets/projects/wd-cover.jpg"
            },
            "fields": {
                "slug": "/projects/wonder-days/"
            }
        }
    }
]