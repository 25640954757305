import React from "react";
import { Route, RouteProps } from "react-router-dom";

interface PublicRouteProps extends RouteProps {
  layout: any;
  component: any;
  exact: boolean;
  name: string;
  path: string;
}

function PublicRoute({
  layout: YourLayout,
  component: YourComponent,
  ...rest
}: PublicRouteProps) {
  return (
    <Route
      {...rest}
      render={(routeProps) => (
        <YourLayout {...rest}>
          {YourComponent && <YourComponent {...routeProps} />}
        </YourLayout>
      )}
    />
  );
}

export default PublicRoute;
