import { Image } from "@chakra-ui/image";
import { Box, Center } from "@chakra-ui/layout";
import React from "react";
import "../styles.css";

export default function Introduction() {
  return (
    <Box className="introduction">
      <Box maxWidth="1440px" margin="0 auto" px={4}>
        <Box
          className="introduc-container"
          display="flex"
          flexWrap={{ base: "wrap", lg: "unset" }}
          color="white"
          pt={{ base: "20px", lg: "0px" }}
        >
          <Box className="item item1" flexGrow={1}>
            <Center h={{ base: "auto", lg: "500px" }}>
              <Box
                h={{ base: "300px", lg: "350px" }}
                w={{ base: "300px", lg: "350px" }}
              >
                <Image
                  borderRadius="10px"
                  src="https://dc.rik.ai/static/red-dc-ab2fecd9df717c4a9267a6c4d65cc7b3.jpg"
                />
              </Box>
            </Center>
          </Box>
          <Box
            className="item item2"
            w={{ base: "100%", lg: "60%" }}
            mb={{ base: "20px", lg: "unset" }}
          >
            <Center h={{ base: "auto", lg: "500px" }}>
              <Box>
                <Box
                  ml="15px"
                  fontSize="50px"
                  fontWeight={450}
                  textAlign={{ base: "center", md: "left" }}
                >
                  About Me
                </Box>
                <Box
                  ml="15px"
                  color="rgba(198,201,216,0.75)"
                  mr={{ base: "15px", lg: "80px" }}
                  textAlign="justify"
                >
                  I've been a technical founder at four startups in three
                  continents with two exits.I've worked at multinationals and
                  bootstrapped startups.I'm a hands-on designer and engineer
                  leading teams to make new things like:games, social networks,
                  many, chatbots, ML platforms, APIs and SDKs, crypto games, NFT
                  art
                </Box>
              </Box>
            </Center>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
