import ProjectDetail from '../pages/ProjectDetail';
import GeneralLayout from '../layouts';
import Home from '../pages/Home';
import Projects from '../pages/Projects';

export const GENERAL_ROUTES = [
  {
    path: '/',
    name: 'HomePage',
    exact: true,
    layout: GeneralLayout,
    component: Home,
  },
  {
    path: '/projects',
    name: 'Projects',
    exact: true,
    layout: GeneralLayout,
    component: Projects,
  },
  {
    path: '/projects/:projectName',
    name: 'Projects',
    exact: true,
    layout: GeneralLayout,
    component: ProjectDetail,
  },
];


